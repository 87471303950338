.generalContainer{
    height: 82px;
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.textContainer{
    color: #343434;
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.separatorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    background-color: black;
    width: 1px;
    margin-right: 43px;
    margin-left: 81px;
}

@media screen and (max-width: 768px){
    .separatorContainer{
        margin-right: 16px;
        margin-left: 16px;
        height: 22px;
    }
    .textContainer{
        margin-left: 16px;
        font-size: 8px;
    }
    .logoContainer{
        margin-right: 16px;
    }
    .logoContainer img {
      width: 60px;
    }
    
    
}